import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "180px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAME/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABnoN5iGf/xAAYEAEAAwEAAAAAAAAAAAAAAAACAAESE//aAAgBAQABBQKkHehOxn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAEBAQEBAAAAAAAAAAAAAAABADECEf/aAAgBAQAGPwL05AsLL//EABwQAAIABwAAAAAAAAAAAAAAAAABETFBUWGB8P/aAAgBAQABPyHHSGl1xolD0Omx/9oADAMBAAIAAwAAABDz7//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxCJj//EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAECAQE/EEj/xAAbEAEBAAIDAQAAAAAAAAAAAAABEQCRIUFRYf/aAAgBAQABPxByWUAFqTU2yuiHMO/zWNeP6wK+y5//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/09f4ee255c1ca9a22f434ce199dbb9f3/59d6e/nyfintechstartupsmeetup.webp 180w"],
            "sizes": "(max-width: 180px) 100vw, 180px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/09f4ee255c1ca9a22f434ce199dbb9f3/4ec73/nyfintechstartupsmeetup.jpg 180w"],
            "sizes": "(max-width: 180px) 100vw, 180px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/09f4ee255c1ca9a22f434ce199dbb9f3/4ec73/nyfintechstartupsmeetup.jpg",
            "alt": "nyfintechstartupsmeetup",
            "title": "nyfintechstartupsmeetup",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`When we quizzed last night’s `}<a parentName="p" {...{
        "href": "http://www.meetup.com/NY-FinTech-Startups/"
      }}>{`NY FinTech Startups Meetup`}</a>{` attendees on whether the average American living on the East Coast spends more or less than ~\\$650/year on the lottery, we were shocked (and impressed) that nearly everyone answered correctly with a resounding "yes".`}</p>
    <p>{`There were 50+ people in attendance for our presentation and we stayed for more than three hours discussing the specificities of starting a financial technology company with other entrepreneurs who are innovating in various pockets of the space.`}</p>
    <p>{`At three years into our venture, we’re hardly the "old guard". But, last night, it sure felt like we’d been around the block longer than most. And while some startups might view an uptick in interest in their space as a negative, we’re actually quite excited to have so many smart people entering our domain.`}</p>
    <p>{`More FinTech startups not only means a better network of entrepreneurs to collaborate on best practices with, it means that large enterprise clients are going to have to become more adept at working with smaller entrepreneurial companies. More streamlined and practiced legal processes, technology integrations and security reviews would not only decrease time to market, but allow more FinTech startups to actually get to market. And if that means more competition, so be it. A little healthy competition never hurt anyone.`}</p>
    <p>{`So, NY FinTech Startups Meetup, thanks for having us and thanks for creating the NY FinTech Startups Meetup to begin with.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      